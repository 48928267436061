const FETCH_ENTERPRISE_BUDGETS_REQUEST = 'FETCH_ENTERPRISE_BUDGETS_REQUEST';
const FETCH_ENTERPRISE_BUDGETS_SUCCESS = 'FETCH_ENTERPRISE_BUDGETS_SUCCESS';
const FETCH_ENTERPRISE_BUDGETS_FAILURE = 'FETCH_ENTERPRISE_BUDGETS_FAILURE';
const CLEAR_ENTERPRISE_BUDGETS = 'CLEAR_ENTERPRISE_BUDGETS';

export {
  FETCH_ENTERPRISE_BUDGETS_REQUEST,
  FETCH_ENTERPRISE_BUDGETS_SUCCESS,
  FETCH_ENTERPRISE_BUDGETS_FAILURE,
  CLEAR_ENTERPRISE_BUDGETS,
};
